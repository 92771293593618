<template>
  <v-app>
    <AdminNavBar></AdminNavBar>
    <v-main class="pt-0">
      <banner></banner>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Banner from "@/components/general/Banner.vue";
import AdminNavBar from "@/components/appbar/admin/AdminNavBar.vue";

export default {
  name: "MainApp",
  components: {
    AdminNavBar,
    Banner,
  },
  data() {
    return {}
  },
  created() {
    this.$store.dispatch('admin/fetchMe')
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Good Bev Co.",
    // all titles will be injected into this template
    titleTemplate: "%s | Good Bev Co.",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Good Bev Co. | Adult Beverages",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>
