<template>
  <!-- <nav-bar-collapsed v-if="this.$vuetify.breakpoint.smAndDown"></nav-bar-collapsed> -->
  <admin-nav-bar-full></admin-nav-bar-full>
</template>

<script>
import AdminNavBarFull from './AdminNavBarFull.vue';

export default {
  components: {AdminNavBarFull},
  name: "AdminNavBar",
};
</script>

<style scoped>

</style>
